import {action, configure, makeObservable, observable} from 'mobx';
import {Inertia} from "@inertiajs/inertia";

export default class ProductDetailStore {
    @observable productsScannedData = [];
    @observable redirect404 = false;
    @observable basketRedirect = false;
    @observable product = [];
    @observable productData = [];
    @observable productDataOneClick = [];
    @observable onlineDiscountOneClick = 0;
    @observable onlineDiscountSpbOneClick = 0;
    @observable productsRelated = [];
    @observable productDeliveryInformation = {
        'pvzList': [],
        'pvzCount': 0,
        'pvzDeliveryDate': null,
        'pvzDeliveryDateExpress': null,
        'shopsList': [],
        'shopsCount': 0,
        'shopsDeliveryDate': null,
        'shopsDeliveryDateExpress': null
    };
    // Тип информации (пвз или фирменные магазины)
    @observable productDeliveryInformationType = '';
    @observable productsModel = [];
    // Размеры
    @observable dataSize = [];
    reviewsCount = 2;

    @observable data = [];
    @observable productDataActive = [];
    @observable preOrderProductDataActive = [];
    @observable dataActiveOneClick = [];
    // Финальная сумма купить в один клик
    @observable priceOneClickFinal = null;
    @observable load = true;
    // прелоадер для формы
    @observable formLoad = '';
    @observable loader = false;
    // Начальное состояние модальных окон
    @observable open = false;
    @observable openShare = false;
    @observable openSize = false;
    @observable openNotSize = false;
    @observable openEntrance = false;
    @observable flagEntrance = false;
    @observable value = '';
    @observable sizeInfo = '';
    @observable sizeInfoError = '';

    @observable id = '';

    // Активный размер
    @observable activeSize = '';
    // Активный размер, используется в детальной карточке товара
    @observable productActiveSize = '';
    // Короткий размер (используется в соул браслетах)
    @observable shortActiveSize = '';

    // Красим точки если окно открыто
    @observable dots = '';
    // Выбранный размер для модалки "Нет нужного размера"
    @observable selectSize = '';
    // ID продукта с конкретным размером
    @observable idData = '';
    size = null;

    @observable accordionItemControl = true;
    @observable accordionItemControl1 = false;
    @observable accordionItemControl2 = false;

    @observable aboutDecorationAccordionState = {};

    constructor(rootStore) {
        makeObservable(this);
        configure({
            enforceActions: "never",
        });
        this.rootStore = rootStore;
    }

    /**
     * Создаем состояния для аккордиона AboutDecoration
     * @param data
     */
    @action aboutDecorationAccordionStateCreate = (data = []) => {
        try {
            if (data.length === 0) return;

            data.forEach((el, i) => {
                this.aboutDecorationAccordionState[`aboutDecoration_${i}`] = el.state;
            });
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Переключение аккордион AboutDecoration
     * @param index
     */
    @action toggleAboutDecorationAccordionState = (index) => {
        try {
            this.aboutDecorationAccordionState[`aboutDecoration_${index}`] = !this.aboutDecorationAccordionState[`aboutDecoration_${index}`]
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Открытие окна "Авторизация или Регистрация"
     */
    @action openModalEntrance() {
        this.openEntrance = true;
        this.flagEntrance = true;
    }

    /**
     * Закрытие окна "Авторизация или Регистрация"
     */
    @action closeModalEntrance() {
        this.openEntrance = false;
        this.flagEntrance = false;
    }

    /**
     * Открытие окна "Нет нужного размера"
     */
    @action openModalNotSize(id) {
        this.id = id;
        this.openNotSize = true;
    }

    /**
     * Закрытие окна "Нет нужного размера"
     */
    @action closeModalNotSize() {
        this.selectSize = '';
        this.id = '';
        this.openNotSize = false;
    }

    /**
     * Открытие окна "Узнать свой размер"
     */
    @action openModalSize() {
        this.openSize = true;
    }

    /**
     * Закрытие окна "Узнать свой размер"
     */
    @action closeModalSize() {
        this.openSize = false;
    }

    @action getSize = (e) => {
        try {
            let round;
            this.value = e.target.value;
            this.value = this.value.replace(/,/, '.')
            if (this.value < 40 || this.value > 79) {
                this.sizeInfoError = 'Введите число от 40 до 79 мм';
                this.sizeInfo = '';
            } else {
                let res = this.value;
                if (res < 42.5) {
                    round = 13.0
                } else if (res >= 42.5 && res < 43) {
                    round = 13.5
                } else if (res >= 43 && res < 45.5) {
                    round = 14.0
                } else if (res >= 45.5 && res < 47) {
                    round = 14.5
                } else if (res >= 47 && res < 48.5) {
                    round = 15.0
                } else if (res >= 48.5 && res < 50) {
                    round = 15.5
                } else if (res >= 50 && res < 52) {
                    round = 16.0
                } else if (res >= 52 && res < 53) {
                    round = 16.5
                } else if (res >= 53 && res < 55) {
                    round = 17.0
                } else if (res >= 55 && res < 56.5) {
                    round = 17.5
                } else if (res >= 56.5 && res < 58) {
                    round = 18.0
                } else if (res >= 58 && res < 60) {
                    round = 18.5
                } else if (res >= 60 && res < 61.5) {
                    round = 19.0
                } else if (res >= 61.5 && res < 63) {
                    round = 19.5
                } else if (res >= 63 && res < 64.5) {
                    round = 20.0
                } else if (res >= 64.5 && res < 66) {
                    round = 20.5
                } else if (res >= 66 && res < 67.5) {
                    round = 21.0
                } else if (res >= 67.5 && res < 69) {
                    round = 21.5
                } else if (res >= 69 && res < 70.5) {
                    round = 22.0
                } else if (res >= 70.5 && res < 72) {
                    round = 22.5
                } else if (res >= 72 && res < 74) {
                    round = 23.0
                } else if (res >= 74 && res < 75.5) {
                    round = 23.5
                } else if (res >= 75.5 && res < 77) {
                    round = 24.0
                } else if (res >= 77 && res < 78.5) {
                    round = 24.5
                } else if (res >= 78.5 && res <= 79) {
                    round = 25.0
                } else {
                    round = 0.0
                }
                this.sizeInfo = 'Ваш размер ' + round;
                this.sizeInfoError = '';
            }
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Открытие подсказки
     */
    @action openTooltip() {
        this.open = true;
        this.dots = 'active';
    }

    /**
     * Закрытие подсказки
     */
    closeTooltip(e) {
        this.open = false;
        this.dots = '';
        e.stopPropagation();
    }


    /**
     * Открытие подсказки кнопка "Поделится"
     */
    openTooltipShare() {
        this.openShare = true;
    }


    /**
     * Закрытие подсказки кнопка "Поделится"
     */
    @action closeTooltipShare(e) {
        this.openShare = false;
        e.stopPropagation();
    }

    /**
     * Модалка в один клик, получаем цену и размерный ряд если есть
     */
    @action getSizeOneClick = async (id) => {
        const values = {
            id: id,
            size: ''
        };

        if (this.activeSize !== '') {
            values.size = this.activeSize;
        }

        // Стучимся на сервак
        const response = await axios.post('/get_size_one_click', values);

        try {
            const {success} = response.data

            if (success.status) {
                this.productDataOneClick = success.data;
                this.dataActiveOneClick = success.dataActive;
                this.onlineDiscountOneClick = success.onlineDiscount;
                this.onlineDiscountSpbOneClick = success.onlineDiscountSbp;

                this.priceOneClickFinal = this.dataActiveOneClick[0].card_price;
                this.activeSize = '';
            } else {
                this.productDataOneClick = [];
                this.dataActiveOneClick = [];
                this.activeSize = '';
            }
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Переключаем размер в модалке "Купить в один клик"
     * @param size
     */
    @action sizeOneClick = (size) => {
        this.dataActiveOneClick = this.productDataOneClick.filter((el) => el.razmer === size);
        this.rootStore.buyOneClickStore.handleChangePriceOneClickFinal();
    };

    /**
     * Переключаем нужный размер
     * @param size - размер
     * @param desiredArray - массив в котором ищем
     */
    @action sizeClick = (size, desiredArray = []) => {
        this.productActiveSize = size;
        this.activeSize = size;
        this.productDataActive = desiredArray.filter((el) => (el.razmer === size));
        this.preOrderProductDataActiveChange(this.productDataActive);
    };

    @action addSelectSize = (id, size) => {
        this.idData = id;
        this.size = size;
    };

    /**
     * Добавление в корзину с карточек без product_data
     * @param id  — id продукта
     * @param article
     */
    @action addBasketCard = async (id, article, price) => {
        try {
            // Стучимся на сервак
            const response = await axios.post('/product', {article: article, type: 'addBasket'});

            const {success} = response.data;
            const {status, data} = success;
            this.product = data.product;

            if (status && this.product.product_data.length > 0) {
                // if (data.notSize) {
                if (this.product.product_data.length === 1 && this.product.product_data[0].razmer === '') {
                    await this.rootStore.basketStore.addOrUpdateProduct(this.product.id, this.product.product_data[0].id, price);
                    // if (this.rootStore.basketStore.basketProducts.some((ls) => ls.idProduct === this.product.id && ls.idProductData === this.product.product_data[0].id)) {
                    //     this.rootStore.basketStore.getBasket();
                    // } else {
                    //     await this.rootStore.basketStore.addOrUpdateProduct(this.product.id, this.product.product_data[0].id, price);
                    // }
                } else {

                    this.data = this.product.product_data.filter((el) => {
                        if (el.kol !== 0 && el.price !== 0) {
                            return el;
                        }
                    });

                    this.productDataActive = this.product.product_data.filter((el) => {
                        if (el.price === this.product.price) {
                            return el;
                        }
                    });

                    if (this.productDataActive.length === 0) {
                        this.productDataActive = this.data.filter((el, i) => i === 0);
                    }

                    if (this.productDataActive.length !== 0) {
                        this.idData = this.productDataActive[0].id;
                        this.productActiveSize = this.productDataActive[0].razmer;
                    } else if (this.product.product_data.length !== 0) {
                        this.productActiveSize = this.product.product_data[0].razmer;
                    }

                    // Открываем выбор размеров
                    this.rootStore.modalStore.openModalSelectSize(this.product.id);
                }
            } else {
                return console.log(`Не удалось получить данные для ${article} по API!`);
            }
        } catch (e) {
            console.log('e', e);
        }
    };

    @action scrollAdd = () => {
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            console.log('e', e);
        }
    };


    /**
     * Получаем нужный размер в модалке "Нет нужного размера"
     * @param el
     */
    @action handleChangeSize(el, id) {
        //Добавляем active
        let elements = document.querySelectorAll('.js-check-size');
        elements.forEach((el) => el.classList.remove('active'));
        el.currentTarget.classList.add("active");
        //Получаем значение из атрибута
        if (id) {
            this.selectSize = id;
        }
    };

    /**
     * Запрос информации о доставке при смене города в детальной карточке товара
     * @param values
     * @returns {Promise<unknown>}
     */
    @action getProductDeliveryInformation = async (values) => {
        Inertia.post(
            location.pathname,
            values
        );
    };


    @action accordionItem = () => {
        this.accordionItemControl = !this.accordionItemControl;
        this.accordionItemControl1 = false;
        this.accordionItemControl2 = false;
    };

    @action accordionItem1 = () => {
        this.accordionItemControl = false;
        this.accordionItemControl1 = !this.accordionItemControl1;
        this.accordionItemControl2 = false;
    };

    @action accordionItem2 = () => {
        this.accordionItemControl = false;
        this.accordionItemControl1 = false;
        this.accordionItemControl2 = !this.accordionItemControl2;
    };

    @action changeAccordionProduct = (accordion) => {
        this.accordionItemControl = false;
        this.accordionItemControl1 = false;
        this.accordionItemControl2 = false;
        this[accordion] = true;
    }

    /**
     * Запоминаем элемент для предзаказа
     * @param productDataActive
     */
    @action preOrderProductDataActiveChange = (productDataActive = []) => {
        if (productDataActive.length === 0 || productDataActive[0].kol !== 0 || productDataActive[0].card_price === 0) return;

        this.preOrderProductDataActive = productDataActive;
    }
}
